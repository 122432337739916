import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Dropzone from 'react-dropzone';
import { TextField, SelectField, Avatar } from 'material-ui';
import ImageDrop from './img/ic-dragdrop-image.svg';
import MenuItem from 'material-ui/MenuItem';
import Checkbox from 'material-ui/Checkbox';
import { Toggle } from 'redux-form-material-ui';
import { Toggle as ToggleDefault } from 'material-ui';
import LinearProgress from 'material-ui/LinearProgress';
import FontIcon from 'material-ui/FontIcon';
import { Router, Route, browserHistory } from 'react-router';
import ReactTooltip from 'react-tooltip';
import ImgPlaceHolder from '../utils/img/place_holder_user.png';
import { Body } from './BodyComponente';
import DatePicker from 'material-ui/DatePicker';
import { connect } from 'react-redux';
import { style } from '../../style';
import { LabelLink } from './utils_styled';
import Loader from '../loader/loader';
import { addImageProcess } from './addImageProcess';
import { FooterPagination } from './Pagination';

require('./formscomponentes.css');

var validUrl = require('valid-url');
class ButtonDefault extends Component {
  constructor() {
    super();
    this.state = {};
    this.submit = this.submit.bind(this);
  }

  submit() {
    this.props.bloqueBtn();
    if (this.props.onClick !== undefined) {
      this.props.onClick();
    }
    setTimeout(() => {
      this.props.enableBtn();
    }, 5000);
  }

  render() {
    const stylebtn = {
      height: '40px',
      'border-radius': '5px',
      'background-color': style.inpuStyle.colorInput,
      border: 'none',
      'margin-left': '15px',
      cursor: 'pointer'
    };
    const styleno = {
      height: '28px',
      'border-radius': '5px',
      'background-color': style.inpuStyle.colorInput,
      border: 'none',
      'margin-left': '15px',
      'pointer-events': 'none'
    };

    const labelstyle = {
      display: 'inline-block',
      width: '150px',
      height: '18px',
      'font-family': 'Rubik',
      'font-size': '15px',
      'font-weight': 'normal',
      'font-style': 'normal',
      'font-stretch': 'normal',
      'line-height': 'normal',
      'letter-spacing': 'normal',
      'text-align': 'center',
      'vertical-align': 'text-top',
      color: '#ffffff'
    };

    return (
      <button
        disabled={this.props.dont_sub}
        type={this.props.type || 'submit'}
        className="btn-click"
        style={this.props.submit_form ? stylebtn : styleno}
        onClick={this.submit}
      >
        <div style={labelstyle}>{this.props.label}</div>
      </button>
    );
  }
}

export class ButtonDefaultNoTimer extends Component {
  render() {
    const stylebtn = {
      height: '40px',
      'border-radius': '5px',
      'background-color': style.inpuStyle.colorInput,
      border: 'none',
      'margin-left': '15px',
      cursor: 'pointer'
    };
    const styleno = {
      height: '28px',
      'border-radius': '5px',
      'background-color': style.inpuStyle.colorInput,
      border: 'none',
      'margin-left': '15px',
      'pointer-events': 'none'
    };

    const labelstyle = {
      display: 'inline-block',
      width: '150px',
      height: '18px',
      'font-family': 'Rubik',
      'font-size': '15px',
      'font-weight': 'normal',
      'font-style': 'normal',
      'font-stretch': 'normal',
      'line-height': 'normal',
      'letter-spacing': 'normal',
      'text-align': 'center',
      'vertical-align': 'text-top',
      color: '#ffffff'
    };

    return (
      <button
        type={this.props.type ? this.props.type : 'button'}
        className="btn-click"
        style={stylebtn}
        onClick={this.props.onClick}
      >
        <div style={labelstyle}>{this.props.label}</div>
      </button>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    submit_form: state.submitting
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    bloqueBtn: () => {
      dispatch({ type: 'SUBMITTING' });
    },
    enableBtn: () => {
      dispatch({ type: 'NO_SUBMITTING' });
    }
  };
};
const ButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ButtonDefault);
export default ButtonContainer;

export class ButtonConfirm extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const btnbody = {
      padding: this.props.padding || '',
      height: this.props.height || '28px',
      'border-radius': '5px',
      background: this.props.isSecondary
        ? 'none'
        : this.props.disabled
        ? '#c9c7c7'
        : style.inpuStyle.colorInput,
      border: this.props.isSecondary
        ? `2px solid ${style.inpuStyle.colorInput}`
        : 'none',
      cursor: this.props.disabled ? 'not-allowed' : 'pointer'
    };

    const labelstyle = {
      'font-family': 'Rubik',
      'font-size': '15px',
      'font-weight': 'normal',
      'font-style': 'normal',
      'font-stretch': 'normal',
      'line-height': 'normal',
      'letter-spacing': 'normal',
      'text-align': 'center',
      'vertical-align': 'text-top',
      color: this.props.isSecondary ? style.inpuStyle.colorInput : '#ffffff'
    };

    return (
      <button
        className={
          this.props.disabled ? 'btn-click nohover-btnclick' : 'btn-click'
        }
        style={btnbody}
        onClick={this.props.onClick}
        disabled={this.props.disabled}
      >
        <div style={labelstyle}>{this.props.label}</div>
      </button>
    );
  }
}

export class ButtonDisable extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const style = {
      height: this.props.height || '28px',
      'border-radius': '5px',
      'background-color': '#c9c7c7',
      border: 'none',
      'margin-left': '15px',
      'pointer-events': 'none'
    };

    const labelstyle = {
      display: 'inline-block',
      width: '150px',
      height: '18px',
      'font-family': 'Rubik',
      'font-size': '15px',
      'font-weight': 'normal',
      'font-style': 'normal',
      'font-stretch': 'normal',
      'line-height': 'normal',
      'letter-spacing': 'normal',
      'text-align': 'center',
      'vertical-align': 'text-top',
      color: '#ffffff'
    };

    return (
      <button className="btn-click" style={style} onClick={this.props.onClick}>
        <div style={labelstyle}>{this.props.label}</div>
      </button>
    );
  }
}

export class DatePickerForm extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const style = {
      height: '28px',
      'border-radius': '5px',
      'background-color': '#1bab60',
      border: 'none',
      cursor: 'pointer'
    };

    const labelstyle = {
      display: 'inline-block',
      'font-family': 'Rubik',
      'font-size': '15px',
      'font-weight': 'normal',
      'font-style': 'normal',
      'font-stretch': 'normal',
      'line-height': 'normal',
      'letter-spacing': 'normal',
      'text-align': 'center',
      'vertical-align': 'text-top',
      color: '#ffffff'
    };

    const minDate = new Date(
      new Date().setFullYear(new Date().getFullYear() - 120)
    );

    return (
      <DatePicker
        onChange={this.props.onChange}
        selected={this.props.selected}
        textFieldStyle={this.props.textFieldStyle}
        style={this.props.style}
        hintText="Selecione a data da contratação"
        container="inline"
        minDate={minDate}
      />
    );
  }
}

export class ButtonDialogTag extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const bodybtn = {
      height: '35px',
      'border-radius': '5px',
      'background-color': style.inpuStyle.colorInput,
      border: 'none',
      cursor: 'pointer',
      width: '100%'
    };

    const labelstyle = {
      display: 'inline-block',
      height: '18px',
      'font-family': 'Rubik',
      'font-size': '15px',
      'font-weight': 'normal',
      'font-style': 'normal',
      'font-stretch': 'normal',
      'line-height': 'normal',
      'letter-spacing': 'normal',
      'text-align': 'center',
      color: '#ffffff'
    };

    return (
      <button
        className="btn-click"
        style={bodybtn}
        onClick={this.props.onClick}
        type={this.props.type}
      >
        <div style={labelstyle}>{this.props.label}</div>
      </button>
    );
  }
}

export class ButtonAddFile extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <button className="btn-add-file" onClick={this.props.onClick}>
        <div className="btn-text-label">{this.props.label}</div>
      </button>
    );
  }
}

export class ButtonDialog extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const style_input = {
      height: '28px',
      'border-radius': '5px',
      'background-color': style.inpuStyle.colorInput,
      border: 'none',
      'margin-left': '45px',
      cursor: 'pointer'
    };

    const labelstyle = {
      display: 'inline-block',
      width: '150px',
      height: '18px',
      'font-family': 'Rubik',
      'font-size': '15px',
      'font-weight': 'normal',
      'font-style': 'normal',
      'font-stretch': 'normal',
      'line-height': 'normal',
      'letter-spacing': 'normal',
      'text-align': 'center',
      color: '#ffffff'
    };

    return (
      <button
        type="subu"
        className="btn-click"
        style={style_input}
        onClick={this.props.onClick}
      >
        <div style={labelstyle}>{this.props.label}</div>
      </button>
    );
  }
}
export class ButtonDraft extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const labelstyle = {
      display: 'inline-block',
      'font-family': 'Rubik',
      'font-size': '15px',
      'font-weight': 'normal',
      'font-style': 'normal',
      'font-stretch': 'normal',
      'line-height': 'normal',
      'letter-spacing': 'normal',
      'text-align': 'center',
      color: '#e20914'
    };

    return (
      <button className="btn-rascunho" onClick={this.props.onClick}>
        <div style={labelstyle}>{this.props.label}</div>
      </button>
    );
  }
}

export class ButtonLogin extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const btnlogin = {
      width: '221px',
      height: '39px',
      'border-radius': '5px',
      'background-color': style.inpuStyle.colorInput,
      border: 'none',
      'margin-left': '15px',
      'margin-top': '20px',
      'margin-bottom': '20px',
      cursor: 'pointer'
    };

    return (
      <button style={btnlogin} onClick={this.props.onClick}>
        <div onClick={this.props.onClick} className="label-btn-login">
          {this.props.isLoading ? <Loader /> : this.props.label}
        </div>
      </button>
    );
  }
}

export const TextFieldDefault = ({
  input,
  label,
  type,
  defaultValue,
  meta: { touched, error, warning },
  ...custom
}) => (
  <div>
    <TextField
      floatingLabelText={label}
      floatingLabelFocusStyle={{ color: style.inpuStyle.colorInput }}
      underlineFocusStyle={{ borderColor: style.inpuStyle.colorInput }}
      type={type}
      errorText={
        touched &&
        ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))
      }
      {...input}
      {...custom}
    />
  </div>
);

export const TextFieldTitle = ({ input, label, type, ...custom }) => (
  <div>
    <TextField
      style={{ borderBottom: 'none' }}
      floatingLabelText={label}
      textareaStyle={{
        fontSize: '21px',
        lineHeight: '28px',
        color: '3b3b3b',
        fontWeight: 'bold',
        borderBottom: 'none',
        width: '90%',
        overflow: 'hidden'
      }}
      floatingLabelFocusStyle={{
        color: '262525',
        fontWeight: 'bold',
        borderBottom: 'none'
      }}
      underlineFocusStyle={{ borderColor: style.inpuStyle.colorInput }}
      fullWidth={true}
      type={type}
      multiLine={true}
      name={custom.name}
      value={custom.value}
      {...input}
      {...custom}
    />
  </div>
);

export const ShortAnswerField = ({
  input,
  label,
  type,
  hasDeleteIcon,
  ...custom
}) => (
  <div className="short-answer__container">
    <TextField
      style={{ borderBottom: 'none' }}
      floatingLabelText={label}
      textareaStyle={{
        fontSize: '21px',
        lineHeight: '28px',
        color: '3b3b3b',
        fontWeight: 'bold',
        borderBottom: 'none',
        width: hasDeleteIcon ? '95%' : '100%'
      }}
      floatingLabelFocusStyle={{
        color: '262525',
        fontWeight: 'bold',
        borderBottom: 'none'
      }}
      underlineFocusStyle={{ borderColor: style.inpuStyle.colorInput }}
      fullWidth={true}
      type={type}
      multiLine={true}
      name={custom.name}
      value={custom.value}
      {...input}
      {...custom}
    />
  </div>
);

export const TextFieldArea = ({
  input,
  label,
  type,
  meta: { touched, error, warning },
  ...custom
}) => (
  <div>
    <TextField
      style={{ borderBottom: 'none' }}
      textareaStyle={{
        fontSize: '12px',
        color: '3b3b3b',
        opacity: '0.8',
        'font-family': 'Montserrat',
        color: '#3b3b3b',
        fontSize: '16px'
      }}
      underlineFocusStyle={{ borderColor: style.inpuStyle.colorInput }}
      hintText={label}
      fullWidth={true}
      type={type}
      multiLine={true}
      underlineFocusStyle={{ borderColor: style.inpuStyle.colorInput }}
      errorText={
        touched &&
        ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))
      }
      {...input}
      {...custom}
    />
  </div>
);

export class TextAreaWithoutForm extends TextField {
  render() {
    return (
      <TextField
        style={{ borderBottom: 'none' }}
        textareaStyle={{ fontSize: '12px', color: '3b3b3b' }}
        underlineFocusStyle={{ borderColor: style.inpuStyle.colorInput }}
        underlineStyle={{ border: 'none' }}
        fullWidth={true}
        type={this.props.type}
        multiLine={true}
        underlineFocusStyle={{ borderColor: style.inpuStyle.colorInput }}
        rowsMax={12}
        onChange={this.props.onChange}
      />
    );
  }
}

export class TextFieldSearch extends TextField {
  constructor() {
    super();

    this.state = {
      errorText: ''
    };
  }
  render() {
    return (
      <TextField
        className={this.props.className}
        style={this.props.style}
        errorText={this.state.error}
        floatingLabelText={this.props.floatingLabelText}
        onChange={this.props.onChange}
        onBlur={this.onBlur}
        floatingLabelFocusStyle={{ color: style.inpuStyle.colorInput }}
        underlineFocusStyle={{ borderColor: style.inpuStyle.colorInput }}
        rows={this.props.rows}
        rowsMax={this.props.rowsMax}
        fullWidth={this.props.fullWidth}
        required={this.props.required}
        value={this.props.value}
        type={this.props.type}
        maxsize={this.props.maxsize}
      />
    );
  }
}

export const TextFieldNumberInput = ({
  input,
  disabled,
  label,
  meta: { touched, error, warning },
  ...custom
}) => (
  <TextField
    textareaStyle={{
      fontWeight: 'bold',
      fontFamily: 'Rubik',
      color: '#3b3b3b',
      fontSize: '16px'
    }}
    floatingLabelText={label}
    floatingLabelFocusStyle={{ color: style.inpuStyle.colorInput }}
    underlineFocusStyle={{ borderColor: style.inpuStyle.colorInput }}
    disabled={disabled}
    type="number"
    errorText={
      touched &&
      ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))
    }
    {...input}
    {...custom}
  />
);
export const LongTextField = ({
  input,
  rows,
  label,
  meta: { touched, error, warning },
  ...custom
}) => (
  <TextField
    textareaStyle={{
      fontWeight: 'bold',
      'font-family': 'Rubik',
      color: '#3b3b3b',
      fontSize: '16px'
    }}
    rows={rows}
    rowsMax={5}
    floatingLabelText={label}
    fullWidth={true}
    multiLine={true}
    floatingLabelFocusStyle={{ color: style.inpuStyle.colorInput }}
    underlineFocusStyle={{ borderColor: style.inpuStyle.colorInput }}
    errorText={
      touched &&
      ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))
    }
    {...input}
    {...custom}
  />
);

export const LongTextLink = ({
  input,
  rows,
  label,
  meta: { touched, error, warning },
  ...custom
}) => (
  <TextField
    textareaStyle={{
      fontWeight: 'bold',
      'font-family': 'Rubik',
      color: '#3b3b3b',
      fontSize: '16px'
    }}
    rows={rows}
    rowsMax={1}
    floatingLabelText={label}
    fullWidth={true}
    multiLine={false}
    floatingLabelFocusStyle={{ color: style.inpuStyle.colorInput }}
    underlineFocusStyle={{ borderColor: style.inpuStyle.colorInput }}
    errorText={
      touched &&
      ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))
    }
    {...input}
    {...custom}
  />
);

export const DropZoneAvatar = (field) => {
  const files = field.input.value;

  async function setImage(filesToUpload) {
    const i = await addImageProcess(URL.createObjectURL(filesToUpload[0]));
    if (i.height > 2000 || i.width > 2000) field.dispatchImageError();
    else field.input.onChange(filesToUpload);
  }

  return (
    <div>
      <Dropzone
        name={field.name}
        onDrop={(filesToUpload, e) => setImage(filesToUpload)}
        className="dropzone-avatar"
      >
        {files[0] !== undefined ? (
          <Avatar
            size={200}
            style={{ background: `url(${files[0].preview}) center / cover` }}
          />
        ) : (
          <Avatar size={200} src={ImgPlaceHolder} />
        )}
      </Dropzone>
      {field.meta.touched && field.meta.error && (
        <span className="error">{field.meta.error}</span>
      )}
      {files && Array.isArray(files) && <ul />}
    </div>
  );
};

export const DropZoneImage = (field) => {
  const files = field.input.value;
  const imagedrop = {
    'border-style': 'dotted',

    'border-radius': '10px',

    'border-color': '#c9c7c7'
  };
  const text = {
    'margin-top': '8%',
    'margin-left': '5px',
    'margin-rigth': '5px',
    'font-family': 'Rubik',
    'font-size': '12px',
    'font-weight': 'normal',
    'font-style': 'normal',
    'font-stretch': 'normal',
    'line-height': 'normal',
    'letter-spacing': 'normal',
    'text-align': 'left',
    color: '#acacac'
  };
  return (
    <div>
      <Body>
        <Dropzone
          name={field.name}
          disableClick={true}
          onDrop={(filesToUpload, e) => field.input.onChange(filesToUpload)}
          style={imagedrop}
        >
          <img
            style={{
              width: '200px',
              height: '200px',
              marginLeft: '40%',
              marginTop: '10%'
            }}
            alt="..."
            src={ImageDrop}
          />
          <br />
        </Dropzone>
      </Body>
      {field.meta.touched && field.meta.error && (
        <span className="error">{field.meta.error}</span>
      )}
      {files && Array.isArray(files) && <ul />}
    </div>
  );
};

export const DropZoneTerm = (field) => {
  const files = field.input.value;

  const imagedrop = {
    'border-style': 'dotted',
    height: '100%',
    width: '100%',
    'border-radius': '10px',
    display: 'flex',
    'align-items': 'center',
    'justify-content': 'space-around',
    'border-color': '#c9c7c7'
  };

  return (
    <div>
      <Dropzone
        name={field.name}
        onDrop={(filesToUpload, e) => field.input.onChange(filesToUpload)}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-around',
          marginTop: '10%'
        }}
      >
        <img
          style={{ width: '200px', height: '100%' }}
          alt="..."
          src={ImageDrop}
        />
      </Dropzone>

      {field.meta.touched && field.meta.error && (
        <span className="error">{field.meta.error}</span>
      )}
      {files && Array.isArray(files) && <ul />}
    </div>
  );
};

export const DropDownSingle = ({
  input,
  label,
  array,
  multiple,
  meta: { touched, error, warning },
  children,
  ...custom
}) => (
  <SelectField
    floatingLabelText={label}
    floatingLabelFocusStyle={{ color: style.inpuStyle.colorInput }}
    underlineFocusStyle={{ borderColor: style.inpuStyle.colorInput }}
    errorText={
      touched &&
      ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))
    }
    {...input}
    onChange={(event, index, value) => input.onChange(value)}
    children={children}
    {...custom}
  >
    {array.map(function(arrayvalue, index) {
      return (
        <MenuItem
          key={arrayvalue}
          value={arrayvalue}
          label={arrayvalue}
          primaryText={arrayvalue}
        />
      );
    })}
  </SelectField>
);

export const DropDownSingleStore = ({
  input,
  label,
  array,
  multiple,
  meta: { touched, error, warning },
  children,
  ...custom
}) => (
  <SelectField
    floatingLabelText={label}
    floatingLabelFocusStyle={{ color: style.inpuStyle.colorInput }}
    underlineFocusStyle={{ borderColor: style.inpuStyle.colorInput }}
    errorText={
      touched &&
      ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))
    }
    {...input}
    onChange={(event, index, value) => input.onChange(value)}
    children={children}
    {...custom}
  >
    {array.map(function(arrayvalue, index) {
      return (
        <MenuItem
          key={arrayvalue.id}
          value={arrayvalue.id}
          primaryText={arrayvalue.name}
        />
      );
    })}
  </SelectField>
);

export const DropDownSingleSector = ({
  input,
  label,
  array,
  multiple,
  meta: { touched, error, warning },
  children,
  ...custom
}) => (
  <SelectField
    floatingLabelText={label}
    floatingLabelFocusStyle={{ color: style.inpuStyle.colorInput }}
    underlineFocusStyle={{ borderColor: style.inpuStyle.colorInput }}
    errorText={
      touched &&
      ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))
    }
    {...input}
    onChange={(event, index, value) => input.onChange(value)}
    children={children}
    {...custom}
  >
    {array.map(function(arrayvalue, index) {
      return (
        <MenuItem
          key={arrayvalue.id_sector}
          value={arrayvalue.id_sector}
          primaryText={arrayvalue.name_sector}
        />
      );
    })}
  </SelectField>
);

export const DropDown = ({
  input,
  label,
  array,
  multiple,
  meta: { touched, error, warning },
  children,
  ...custom
}) => (
  <SelectField
    floatingLabelText={label}
    floatingLabelFocusStyle={{ color: style.inpuStyle.colorInput }}
    underlineFocusStyle={{ borderColor: style.inpuStyle.colorInput }}
    multiple={multiple}
    errorText={
      touched &&
      ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))
    }
    {...input}
    onChange={(event, index, value) => input.onChange(value)}
    children={children}
    {...custom}
  >
    {array.map(function(arrayvalue, index) {
      if (input.value.includes(arrayvalue)) {
        return (
          <MenuItem
            key={arrayvalue}
            value={arrayvalue}
            label={arrayvalue}
            primaryText={
              <CheckboxDefault
                checked={true}
                value={arrayvalue}
                label={arrayvalue}
              />
            }
          />
        );
      } else {
        return (
          <MenuItem
            key={arrayvalue}
            value={arrayvalue}
            label={arrayvalue}
            primaryText={
              <CheckboxDefault
                checked={false}
                value={arrayvalue}
                label={arrayvalue}
              />
            }
          />
        );
      }
    })}
  </SelectField>
);

export class TextFieldLongText extends TextField {
  constructor() {
    super();

    this.state = {
      errorText: ''
    };

    this.onBlur = this.onBlur.bind(this);
  }

  onBlur(event) {
    if (event.target.value.length === 0 && this.props.required) {
      this.setState({ error: this.props.errorText });
    } else {
      this.setState({ error: '' });
    }
  }

  render() {
    if (this.props.valid === true) {
      return (
        <TextField
          className={this.props.className}
          style={this.props.style}
          errorText={this.state.error}
          floatingLabelText={this.props.floatingLabelText}
          onChange={this.props.onChange}
          multiLine={this.props.multiLine || true}
          onBlur={this.onBlur}
          floatingLabelFocusStyle={{ color: style.inpuStyle.colorInput }}
          underlineFocusStyle={{ borderColor: style.inpuStyle.colorInput }}
          rows={this.props.rows}
          rowsMax={this.props.rowsMax}
          fullWidth={this.props.fullWidth}
          required={this.props.required}
          value={this.props.value}
          type={this.props.type}
          maxsize={this.props.maxsize}
        />
      );
    } else {
      return (
        <TextField
          className={this.props.className}
          style={this.props.style}
          errorText={this.props.errorText}
          floatingLabelText={this.props.floatingLabelText}
          onChange={this.props.onChange}
          multiLine={this.props.multiLine || true}
          onBlur={this.onBlur}
          floatingLabelFocusStyle={{ color: style.inpuStyle.colorInput }}
          underlineFocusStyle={{ borderColor: style.inpuStyle.colorInput }}
          rows={this.props.rows}
          rowsMax={this.props.rowsMax}
          fullWidth={this.props.fullWidth}
          required={this.props.required}
          value={this.props.value}
          type={this.props.type}
          maxsize={this.props.maxsize}
        />
      );
    }
  }
}

export class TextFieldWithoutForm extends TextField {
  constructor() {
    super();

    this.state = {
      errorText: ''
    };

    this.onBlur = this.onBlur.bind(this);
  }

  onBlur(event) {
    if (event.target.value.length === 0 && this.props.required) {
      this.setState({ error: this.props.errorText });
    } else {
      this.setState({ error: '' });
    }
  }

  render() {
    if (this.props.valid === true) {
      return (
        <TextField
          className={this.props.className}
          style={this.props.style}
          errorText={this.state.error}
          floatingLabelText={this.props.floatingLabelText}
          onChange={this.props.onChange}
          multiLine={this.props.multiLine}
          onBlur={this.onBlur}
          floatingLabelFocusStyle={{ color: style.inpuStyle.colorInput }}
          underlineFocusStyle={{ borderColor: style.inpuStyle.colorInput }}
          rows={this.props.rows}
          rowsMax={this.props.rowsMax}
          fullWidth={false}
          required={this.props.required}
          value={this.props.value}
          type={this.props.type}
          disabled={this.props.disabled}
        />
      );
    } else {
      return (
        <TextField
          className={this.props.className}
          style={this.props.style}
          errorText={this.state.error}
          floatingLabelText={this.props.floatingLabelText}
          onChange={this.props.onChange}
          multiLine={this.props.multiLine}
          onBlur={this.onBlur}
          floatingLabelFocusStyle={{ color: style.inpuStyle.colorInput }}
          underlineFocusStyle={{ borderColor: style.inpuStyle.colorInput }}
          rows={this.props.rows}
          rowsMax={this.props.rowsMax}
          fullWidth={false}
          required={this.props.required}
          value={this.props.value}
          type={this.props.type}
          disabled={this.props.disabled}
        />
      );
    }
  }
}
export class DropDownDefault extends TextField {
  constructor() {
    super();

    this.state = {
      errorText: '',
      array: [],
      checked: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false
      ],
      checkeddefault: [
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false
      ]
    };

    this.onBlur = this.onBlur.bind(this);
  }

  onBlur(event) {
    if ((this.props.value === undefined && this, this.props.required)) {
      this.setState({ error: this.props.errorText });
    }
  }

  updateCheck(event, i) {
    if (this.state.checked[event] === true) {
      this.state.checked[event] = false;
      this.setState({ error: '' });
    } else {
      this.state.checked[event] = true;
      this.setState({ error: '' });
    }
  }

  render() {
    if (this.props.valid === true) {
      return (
        <SelectField
          errorText={this.state.error}
          floatingLabelText={this.props.floatingLabelText}
          onChange={this.props.onChange}
          onBlur={this.onBlur}
          floatingLabelFocusStyle={{ color: style.inpuStyle.colorInput }}
          underlineFocusStyle={{ borderColor: style.inpuStyle.colorInput }}
          required={this.props.required}
          value={this.props.value}
          type={this.props.type}
          maxsize={this.props.maxsize}
          multiple={this.props.multiple}
          disabled={this.props.disabled}
          style={this.props.style}
          fullWidth={this.props.fullWidth}
          floatingLabelStyle={this.props.floatingLabelStyle}
        >
          {this.props.array.map(
            function(arrayvalue, index) {
              var checked = false;

              if (this.props.value !== undefined) {
                this.props.value.map(function(tag) {
                  if (tag === arrayvalue) {
                    checked = true;
                  }
                });
              }

              return (
                <MenuItem
                  key={arrayvalue}
                  value={arrayvalue}
                  label={arrayvalue}
                  primaryText={
                    <CheckboxDefault
                      checked={checked}
                      onCheck={this.updateCheck.bind(this, index)}
                      value={arrayvalue}
                      label={arrayvalue}
                    />
                  }
                />
              );
            }.bind(this)
          )}
        </SelectField>
      );
    } else {
      return (
        <SelectField
          errorText={this.props.errorText}
          floatingLabelText={this.props.floatingLabelText}
          onChange={this.props.onChange}
          onBlur={this.onBlur}
          floatingLabelFocusStyle={{ color: style.inpuStyle.colorInput }}
          underlineFocusStyle={{ borderColor: style.inpuStyle.colorInput }}
          required={this.props.required}
          value={this.props.value}
          type={this.props.type}
          maxsize={this.props.maxsize}
          multiple={this.props.multiple}
          disabled={this.props.disabled}
          style={this.props.style}
          fullWidth={this.props.fullWidth}
          floatingLabelStyle={this.props.floatingLabelStyle}
        >
          {this.props.array.map(
            function(arrayvalue, index) {
              var checked = false;

              if (this.props.value !== '') {
                if (this.props.value !== undefined) {
                  this.props.value.map(function(tag) {
                    if (tag === arrayvalue) {
                      checked = true;
                    }
                  });
                }
              }

              return (
                <MenuItem
                  key={arrayvalue}
                  value={arrayvalue}
                  label={arrayvalue}
                  primaryText={
                    <CheckboxDefault
                      checked={checked}
                      onCheck={this.updateCheck.bind(this, index)}
                      value={arrayvalue}
                      label={arrayvalue}
                    />
                  }
                />
              );
            }.bind(this)
          )}
        </SelectField>
      );
    }
  }
}

export class DropDownCorsers extends TextField {
  constructor() {
    super();

    this.state = {
      errorText: '',
      array: []
    };
  }

  render() {
    return (
      <SelectField
        errorText={this.props.errorText}
        floatingLabelText={this.props.floatingLabelText}
        onChange={this.props.onChange}
        floatingLabelFocusStyle={{ color: style.inpuStyle.colorInput }}
        underlineFocusStyle={{ borderColor: style.inpuStyle.colorInput }}
        required={this.props.required}
        value={this.props.value}
        type={this.props.type}
        maxsize={this.props.maxsize}
        multiple={this.props.multiple}
      >
        {this.props.array.map(
          function(arrayvalue, index) {
            return (
              <MenuItem key={arrayvalue.id} value={arrayvalue.id}>
                <CheckboxDefault
                  value={arrayvalue.id}
                  label={arrayvalue.title}
                />
              </MenuItem>
            );
          }.bind(this)
        )}
      </SelectField>
    );
  }
}

export class DropDownModule extends TextField {
  constructor() {
    super();

    this.state = {
      errorText: '',
      array: []
    };
  }

  render() {
    return (
      <SelectField
        errorText={this.props.errorText}
        floatingLabelText={this.props.floatingLabelText}
        onChange={this.props.onChange}
        floatingLabelFocusStyle={{ color: style.inpuStyle.colorInput }}
        underlineFocusStyle={{ borderColor: style.inpuStyle.colorInput }}
        required={this.props.required}
        value={this.props.value}
        type={this.props.type}
        maxsize={this.props.maxsize}
        multiple={this.props.multiple}
      >
        {this.props.array.map(
          function(arrayvalue, index) {
            return (
              <MenuItem key={arrayvalue.id} value={arrayvalue.id}>
                <CheckboxDefault
                  value={arrayvalue.id}
                  label={arrayvalue.name}
                />
              </MenuItem>
            );
          }.bind(this)
        )}
      </SelectField>
    );
  }
}

export class DropDownDefaultOnlyOne extends TextField {
  constructor() {
    super();

    this.state = {
      errorText: '',
      array: []
    };

    this.onBlur = this.onBlur.bind(this);
  }

  onBlur(event) {
    if ((this.props.value === undefined && this, this.props.required)) {
      this.setState({ error: this.props.errorText });
    }
  }

  render() {
    const hasExternalRegister =
      style.teamName.name === 'Vedacit' ||
      style.teamName.name === 'mestreconstrutor' ||
      style.teamName.name === 'Ecom';
    if (this.props.valid) {
      return (
        <SelectField
          errorText={this.state.error}
          floatingLabelText={this.props.floatingLabelText}
          onChange={this.props.onChange}
          floatingLabelFocusStyle={{ color: style.inpuStyle.colorInput }}
          underlineFocusStyle={{ borderColor: style.inpuStyle.colorInput }}
          required={this.props.required}
          value={this.props.value}
          type={this.props.type}
          maxsize={this.props.maxsize}
          selectedMenuItemStyle={{
            color: style.inpuStyle.colorInput,
            borderColor: style.inpuStyle.colorInput,
            fontWeight: 'bold'
          }}
          multiple={this.props.multiple}
          disabled={this.props.disabled}
          style={this.props.style}
          floatingLabelStyle={this.props.floatingLabelStyle}
          fullWidth={this.props.fullWidth}
        >
          {Array.isArray(this.props.array) &&
            this.props.array.map(
              function(arrayvalue) {
                return (
                  <MenuItem
                    key={arrayvalue}
                    value={arrayvalue}
                    primaryText={arrayvalue}
                  />
                );
              }.bind(this)
            )}

          {Array.isArray(this.props.elementsObject) &&
            this.props.elementsObject.map((elementsObject) => {
              return (
                <MenuItem
                  key={elementsObject.id}
                  value={elementsObject.value}
                  primaryText={
                    <div className="dropDown-text-btn">
                      {elementsObject.image && (
                        <img
                          src={elementsObject.image}
                          alt={elementsObject.text}
                        />
                      )}
                    </div>
                  }
                />
              );
            })}

          {Array.isArray(this.props.elementsName) &&
            this.props.elementsName.map(
              function(element) {
                return (
                  <MenuItem
                    key={element}
                    value={element}
                    primaryText={element.name}
                  />
                );
              }.bind(this)
            )}
        </SelectField>
      );
    } else {
      return (
        <SelectField
          dropDownMenuProps={this.props.dropdownMenuProps}
          errorText={this.props.errorText}
          floatingLabelText={this.props.floatingLabelText}
          onChange={this.props.onChange}
          floatingLabelFocusStyle={{ color: style.inpuStyle.colorInput }}
          floatingLabelStyle={this.props.floatingLabelStyle}
          underlineFocusStyle={{ borderColor: style.inpuStyle.colorInput }}
          required={this.props.required}
          value={this.props.value}
          type={this.props.type}
          maxsize={this.props.maxsize}
          disabled={this.props.disabled}
          multiple={this.props.multiple}
          style={this.props.style}
          fullWidth={this.props.fullWidth}
        >
          {Array.isArray(this.props.array) &&
            this.props.array.map(
              function(arrayvalue) {
                return (
                  <MenuItem
                    key={arrayvalue}
                    value={arrayvalue}
                    primaryText={arrayvalue}
                  />
                );
              }.bind(this)
            )}

          {Array.isArray(this.props.elementsObject) &&
            this.props.elementsObject.map((elementsObject) => {
              return (
                <MenuItem
                  id="btn-teste-color"
                  key={elementsObject.id}
                  value={
                    hasExternalRegister
                      ? elementsObject.id
                      : elementsObject.value
                  }
                  primaryText={
                    hasExternalRegister ? (
                      elementsObject.name
                    ) : (
                      <div className="dropDown-text-btn">
                        {elementsObject.image && (
                          <img
                            src={elementsObject.image}
                            alt={elementsObject.text}
                          />
                        )}
                      </div>
                    )
                  }
                />
              );
            })}

          {Array.isArray(this.props.elementsName) &&
            this.props.elementsName.map(
              function(element) {
                return (
                  <MenuItem
                    key={element}
                    value={element}
                    primaryText={element.name}
                  />
                );
              }.bind(this)
            )}

          {this.props.withPagination && (
            <FooterPagination
              twoLines={true}
              activePage={this.props.activePage}
              totalItemsCount={this.props.totalItemsCount}
              handlePageChange={this.props.handlePageChange}
            />
          )}
        </SelectField>
      );
    }
  }
}

export class DropDownDefaultById extends TextField {
  constructor() {
    super();

    this.state = {
      errorText: '',
      array: []
    };

    this.onBlur = this.onBlur.bind(this);
  }

  onBlur(event) {
    if ((this.props.value === undefined && this, this.props.required)) {
      this.setState({ error: this.props.errorText });
    }
  }

  render() {
    if (this.props.valid) {
      return (
        <SelectField
          errorText={this.state.error}
          floatingLabelText={this.props.floatingLabelText}
          onChange={this.props.onChange}
          floatingLabelFocusStyle={{ color: style.inpuStyle.colorInput }}
          underlineFocusStyle={{ borderColor: style.inpuStyle.colorInput }}
          required={this.props.required}
          value={this.props.value}
          type={this.props.type}
          maxsize={this.props.maxsize}
          multiple={this.props.multiple}
          disabled={this.props.disabled}
          style={this.props.style}
          floatingLabelStyle={this.props.floatingLabelStyle}
          fullWidth={this.props.fullWidth}
        >
          {this.props.array.map(
            function(arrayvalue, index) {
              if (arrayvalue !== undefined) {
                return (
                  <MenuItem
                    key={arrayvalue.id}
                    value={arrayvalue.name}
                    primaryText={arrayvalue.name}
                  />
                );
              }
            }.bind(this)
          )}
        </SelectField>
      );
    } else {
      return (
        <SelectField
          dropDownMenuProps={this.props.dropdownMenuProps}
          errorText={this.props.errorText}
          floatingLabelText={this.props.floatingLabelText}
          onChange={this.props.onChange}
          floatingLabelFocusStyle={{ color: style.inpuStyle.colorInput }}
          floatingLabelStyle={this.props.floatingLabelStyle}
          underlineFocusStyle={{ borderColor: style.inpuStyle.colorInput }}
          required={this.props.required}
          value={this.props.value}
          type={this.props.type}
          maxsize={this.props.maxsize}
          multiple={this.props.multiple}
          style={this.props.style}
          fullWidth={this.props.fullWidth}
        >
          {this.props.array.map(
            function(arrayvalue, index) {
              return (
                <MenuItem
                  key={arrayvalue.id}
                  value={arrayvalue.name_sector}
                  primaryText={
                    arrayvalue.name_sector && arrayvalue.name_sector.length > 30
                      ? `${arrayvalue.name_sector.slice(0, 30)}...`
                      : arrayvalue.name_sector
                  }
                />
              );
            }.bind(this)
          )}

          {this.props.withPagination && (
            <FooterPagination
              twoLines={true}
              activePage={this.props.activePage}
              totalItemsCount={this.props.totalItemsCount}
              handlePageChange={this.props.handlePageChange}
            />
          )}
        </SelectField>
      );
    }
  }
}

export class DropZone extends TextField {
  constructor() {
    super();

    this.state = {
      errorText: ''
    };
  }
  render() {
    const imagedrop = {
      'margin-top': '20px',
      'border-style': 'dotted',
      'border-radius': '10px',
      height: '147px',
      'border-color': '#c9c7c7',
      position: 'relative'
    };
    const image = {
      'margin-left': '15%',
      'margin-top': '2px',
      width: '200px',
      height: '100px'
    };
    const text = {
      'margin-top': '8%',
      'margin-left': '5px',
      'margin-rigth': '5px',
      'font-family': 'Rubik',
      'font-size': '12px',
      'font-weight': 'normal',
      'font-style': 'normal',
      'font-stretch': 'normal',
      'line-height': 'normal',
      'letter-spacing': 'normal',
      'text-align': 'left',
      color: '#acacac'
    };

    if (this.props.preview.preview === undefined) {
      this.setState({ img: ImageDrop });
    } else {
      this.setState({ img: this.props.preview.preview });
    }

    return (
      <Dropzone style={imagedrop}>
        <img alt="..." style={image} src={this.state.img} />
        <br />
        <span style={text}>
          Arraste ou selecione uma imagem ou vídeo de capa*
        </span>
      </Dropzone>
    );
  }
}

export class DropZonePublication extends TextField {
  constructor() {
    super();

    this.state = {
      errorText: '',
      img: null
    };
  }
  render() {
    const imagedrop = {
      width: '320px',
      'margin-left': '20%',
      'margin-top': '20px',
      'border-style': 'dotted',
      'border-radius': '10px',
      height: '147px',
      'border-color': '#c9c7c7'
    };
    const image = {
      'margin-left': '15%',
      'margin-top': '2px',
      width: '200px',
      height: '100px'
    };
    const text = {
      'margin-top': '8%',

      'margin-rigth': '5px',
      'font-family': 'Rubik',
      'font-size': '12px',
      'font-weight': 'normal',
      'font-style': 'normal',
      'font-stretch': 'normal',
      'line-height': 'normal',
      'letter-spacing': 'normal',
      'text-align': 'center',
      color: '#acacac'
    };

    if (this.props.preview.preview === undefined) {
      this.setState({ img: ImageDrop });
    } else {
      this.setState({ img: this.props.preview.preview });
    }

    return (
      <Dropzone style={imagedrop}>
        <img alt="..." style={image} src={this.state.img} />
        <br />
        <span style={text}>
          Arraste ou selecione uma imagem ou vídeo de capa*
        </span>
      </Dropzone>
    );
  }
}

export class DropZonePublicationEdit extends TextField {
  constructor() {
    super();

    this.state = {
      errorText: '',
      img: null
    };
  }
  render() {
    const imagedrop = {
      width: '320px',
      'margin-left': '20%',
      'margin-top': '20px',
      'border-style': 'dotted',
      'border-radius': '10px',
      height: '147px',
      'border-color': '#c9c7c7'
    };
    const image = {
      'margin-left': '15%',
      'margin-top': '2px',
      width: '200px',
      height: '100px'
    };
    const text = {
      'margin-top': '8%',

      'margin-rigth': '5px',
      'font-family': 'Rubik',
      'font-size': '12px',
      'font-weight': 'normal',
      'font-style': 'normal',
      'font-stretch': 'normal',
      'line-height': 'normal',
      'letter-spacing': 'normal',
      'text-align': 'center',
      color: '#acacac'
    };

    return (
      <Dropzone style={imagedrop}>
        <img alt="..." style={image} src={this.state.img} />
        <br />
        <span style={text}>
          Arraste ou selecione uma imagem ou vídeo de capa*
        </span>
      </Dropzone>
    );
  }
}

export class CheckboxDefault extends Checkbox {
  constructor() {
    super();

    this.state = {
      errorText: '',
      checked: false
    };
  }

  render() {
    return (
      <Checkbox
        style={{ width: '10px' }}
        onCheck={this.props.onCheck}
        checked={this.props.checked}
        iconStyle={{ fill: style.inpuStyle.colorInput }}
        label={this.props.label}
      />
    );
  }
}

export class CheckboxDefaultList extends Checkbox {
  constructor() {
    super();

    this.state = {
      errorText: '',
      checked: false
    };
  }

  render() {
    return (
      <Checkbox
        style={this.props.style ? this.props.style : { marginLeft: '50px' }}
        checked={this.props.checked}
        onCheck={this.props.onCheck}
        iconStyle={{ fill: style.inpuStyle.colorInput }}
      />
    );
  }
}

export class Progress extends Component {
  constructor() {
    super();

    this.state = {
      errorText: ''
    };
  }

  render() {
    return (
      <LinearProgress
        style={{ height: '1px', background: '#FFFFFF' }}
        color="#ccccccc"
        mode="indeterminate"
      />
    );
  }
}

export class ProgressDeterminate extends Component {
  constructor() {
    super();

    this.state = {
      errorText: ''
    };
  }

  render() {
    return (
      <LinearProgress
        value={this.props.value}
        style={{ height: '1px', background: '#FFFFFF' }}
        color={style.inpuStyle.colorInput}
        mode="determinate"
      />
    );
  }
}

export class AlternativeLabel extends Checkbox {
  constructor() {
    super();

    this.state = {
      errorText: ''
    };
  }
  render() {
    const labelalternative = {
      width: '12px',
      height: '12px',
      'font-family': 'Rubik',
      'font-size': '12px',
      'font-weight': '500',
      'font-style': 'normal',
      'font-stretch': 'normal',
      'line-height': 'normal',
      'letter-spacing': 'normal',
      'text-align': 'left',
      color: '#e20914',
      'margin-top': '40px'
    };

    return <label style={labelalternative}>{this.props.label}</label>;
  }
}

export class AlternativeToggle extends Checkbox {
  constructor() {
    super();

    this.state = {
      errorText: ''
    };
  }
  render() {
    const styles = {
      block: {
        maxWidth: 250
      },
      toggle: {
        marginBottom: 16
      },
      thumbOff: {
        backgroundColor: '#ff0000'
      },
      trackOff: {
        backgroundColor: '#ff9d9d'
      },
      thumbSwitched: {
        backgroundColor: '#1bab60'
      },
      trackSwitched: {
        backgroundColor: '#1cab62'
      },
      labelStyle: {
        color: 'red'
      },
      'margin-top': '40px'
    };

    return (
      <div>
        <ToggleDefault
          data-tip
          data-for="alternative"
          thumbStyle={styles.thumbOff}
          defaultToggled={this.props.defaultToggled}
          trackStyle={styles.trackOff}
          thumbSwitchedStyle={styles.thumbSwitched}
          trackSwitchedStyle={styles.trackSwitched}
          disabled={this.props.disabled}
          style={styles}
          onToggle={this.props.onToggle}
        />
        <ReactTooltip id="alternative" aria-haspopup="true" role="example">
          <p>Selecione a alternativa corrreta</p>
        </ReactTooltip>
      </div>
    );
  }
}

export class AlternativeToggleCourse extends Checkbox {
  constructor() {
    super();

    this.state = {
      errorText: ''
    };
  }
  render() {
    const styles = {
      thumbOff: {
        backgroundColor: '#b8b8b8'
      },
      trackOff: {
        backgroundColor: '#b8b8b8'
      },
      thumbSwitched: {
        backgroundColor: '#1bab60'
      },
      trackSwitched: {
        backgroundColor: '#1cab62'
      },
      labelStyle: {
        color: 'red'
      }
    };

    return (
      <ToggleDefault
        style={{ width: '30%', marginTop: '10px' }}
        thumbStyle={styles.thumbOff}
        defaultToggled={this.props.defaultToggled}
        trackStyle={styles.trackOff}
        thumbSwitchedStyle={styles.thumbSwitched}
        trackSwitchedStyle={styles.trackSwitched}
        disabled={this.props.disabled}
        style={styles}
        onToggle={this.props.onToggle}
      />
    );
  }
}

export class DefaultToggle extends Checkbox {
  constructor() {
    super();

    this.state = {
      errorText: ''
    };
  }
  render() {
    const styles = {
      block: {
        maxWidth: 250
      },
      thumbOff: {
        backgroundColor: '#ffcccc'
      },
      trackOff: {
        backgroundColor: '#ff9d9d'
      },
      thumbSwitched: {
        backgroundColor: '#1bab60'
      },
      trackSwitched: {
        backgroundColor: '#1cab62'
      },
      labelStyle: {
        color: 'red'
      }
    };

    return (
      <Toggle
        thumbStyle={styles.thumbOff}
        defaultToggled={this.props.defaultToggled}
        trackStyle={styles.trackOff}
        thumbSwitchedStyle={styles.thumbSwitched}
        trackSwitchedStyle={styles.trackSwitched}
        disabled={this.props.disabled}
        style={styles}
        onToggle={this.props.onToggle}
      />
    );
  }
}

export class DropImageVIew extends Checkbox {
  constructor() {
    super();

    this.state = {
      errorText: ''
    };
  }
  alert() {
    alert('re');
  }
  render() {
    const bodyviewimage = {
      border: 'dotted',
      height: '100%',
      width: '100%',
      'border-color': '#c9c7c7'
    };
    const imagedrop = {
      'margin-top': '20px',
      'border-style': 'dotted',
      'border-radius': '10px',
      height: '147px',
      'border-color': '#c9c7c7'
    };
    const image = {
      'margin-left': '50%',
      'margin-top': '20%',
      width: '200px'
    };
    const text = {
      'margin-top': '8%',
      'margin-left': '40%',
      'margin-rigth': '5px',
      'font-family': 'Rubik',
      'font-size': '12px',
      'font-weight': 'normal',
      'font-style': 'normal',
      'font-stretch': 'normal',
      'line-height': 'normal',
      'letter-spacing': 'normal',
      'text-align': 'center',
      color: '#acacac'
    };

    return (
      <Dropzone
        onMouseLeave={this.props.onMouseLeave}
        onDrop={this.props.onDrop}
        onChange={this.props.onChange}
        style={bodyviewimage}
      >
        <div className="row">
          <div className="col-md-10">
            <img alt="..." style={image} src={ImageDrop} />
            <br />
            <span style={text}>
              Arraste ou selecione uma imagem ou vídeo de capa*
            </span>
          </div>
        </div>
      </Dropzone>
    );
  }
}

export class ExternPlayer extends Checkbox {
  constructor() {
    super();

    this.state = {
      errorText: '',
      ImageDrop: null
    };
  }
  render() {
    const style = {
      border: 'none'
    };

    return (
      <iframe
        className="preview-publication-externo"
        src={this.props.src}
        allow="autoplay; encrypted-media"
        allowfullscreen
      />
    );
  }
}

export class Link extends Component {
  constructor() {
    super();

    this.state = {
      errorText: '',
      ImageDrop: null
    };
    this.onClick = this.onClick.bind(this);
  }
  onClick() {
    browserHistory.push(this.props.to);
  }
  render() {
    return (
      <LabelLink onClick={this.onClick} selected={this.props.select}>
        {this.props.name}
      </LabelLink>
    );
  }
}

export const LinkMemoized = React.memo(Link);

export class LengthField extends Component {
  constructor() {
    super();

    this.state = {
      errorText: '',
      ImageDrop: null
    };
    this.onClick = this.onClick.bind(this);
  }
  onClick() {
    browserHistory.push(this.props.to);
  }
  render() {
    const style = {
      border: 'none'
    };

    return (
      <label className="length-field-text">
        {this.props.length + '/' + this.props.maxlength}
      </label>
    );
  }
}

export class LinearProgressDeterminated extends Component {
  constructor() {
    super();

    this.state = {
      errorText: '',
      ImageDrop: null
    };
  }

  render() {
    const style = {
      border: 'none'
    };

    return (
      <LinearProgress
        style={{ marginTop: '2px' }}
        color={style.inpuStyle.colorInput}
        mode="determinate"
        value={this.props.value}
      />
    );
  }
}

export class AvatarUser extends Component {
  render() {
    const style = {
      border: 'none'
    };

    return (
      <Avatar
        src={
          this.props.src === null ||
          this.props.src === undefined ||
          !validUrl.isUri(this.props.src)
            ? ImgPlaceHolder
            : this.props.src
        }
      />
    );
  }
}
